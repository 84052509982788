/* eslint-disable react/no-unknown-property */

const Youtube = ({ source = "https://www.youtube.com/watch?v=MLpWrANjFbI", className = "", ...props }) => {
  // console.log(source, props);

  //new added
  // if (!source) {
  //   return <></>;
  // }
  return (
    <div className={`plyr__video-embed ${className}`} id="player" {...props}>
      <iframe
        src={`${source}?origin=${window.location.origin}&iv_load_policy=3&amp;modestbranding=1&amp;playsinline=1&amp;showinfo=0&amp;rel=0&amp;enablejsapi=1`}
        // src={`${source}&feature=youtu.be`}
        allowfullscreen
        allowtransparency
        allow="autoplay"
      ></iframe>
      {props["data-poster"] && <div className="preload_poster" style={{ background: `url(${props["data-poster"]})` }}></div>}
    </div>
  );
};

export default Youtube;
